<template>
    <!--楼盘地图位置-->
    <div id="container">
<!--        <div><span>楼盘纬度{{houseLat}}</span>-->
<!--        <span>楼盘经度{{houseLng}}</span>-->
<!--        <span>员工纬度{{personLat}}</span>-->
<!--        <span>员工经度{{houseLng}}</span></div>-->
    </div>

</template>

<script>
    import BMap from 'BMap'
    export default {
        name: "estateMap",
        props: {
            //楼盘经度
            houseLng: {
                type: Number,
                default:0
            },
            //楼盘纬度
            houseLat: {
                type: Number,
                default:0
            },
            //人员所在位置经度
            personLng: {
                type: Number,
                default:0
            },
            //人员所在位置纬度
            personLat: {
                type: Number,
                default:0
            },
        },
        data(){
            return{

            }
        },
        mounted() {
            this.createCircle()
        },
        methods:{
            addMarker(staffPoint,map){
                var marker = new BMap.Marker(staffPoint);
                map.addOverlay(marker);
            },

            //创建圆形区域
            createCircle: function () {
                var map = new BMap.Map("container");
                //右上角缩小、放大按钮
                var top_right_navigation = new BMap.NavigationControl({anchor: BMAP_ANCHOR_TOP_RIGHT, type: BMAP_NAVIGATION_CONTROL_SMALL});
                //创建中心点
                var pt = new BMap.Point(this.houseLng, this.houseLat)
                map.centerAndZoom(pt, 19)
                var marker = new BMap.Marker(pt)
                var circle = new BMap.Circle(pt, 30, {
                    strokeColor: 'Red',
                    strokeWeight: 1,
                    strokeOpacity: 1,
                    Color: 'Red',
                    fillColor: '#f03',
                    fillOpacity: 0.2
                })// 创建圆形区域
                map.addOverlay(marker)
                map.addOverlay(circle)
                map.addControl(top_right_navigation);
                //员工经纬度
                // var lngSpan = 121.525704;
                // var latSpan = 38.8578;
                // var staffPoint = new BMap.Point('121.6608', '38.92583');
                var staffPoint = new BMap.Point(this.personLng, this.personLat);
                this.addMarker(staffPoint,map);
            },




        }

    }

</script>

<style scoped>
    /*地图样式*/
    #container{
        height:90vh;
        width: 92%;
        margin: 0 auto;
        margin-top: 50px;
        border-radius: 20px;
    }
</style>
